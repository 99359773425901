<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>问题反馈</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="昵称">
                                <el-input v-model="search.user_nick"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="联系方式">
                                <el-input v-model="search.mobile"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="创建时间">
                                <el-date-picker style="width: 100%" v-model="searchtime" type="daterange"
                                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                    format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="问题类型">
                                <el-select v-model="search.category_id" placeholder="请选择类型" style="width: 100%">
                                    <el-option label="全部" :value=0></el-option>
                                    <el-option v-for="(item, index) in categoryList" :key="index" :label="item.name"
                                        :value="item.id" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                            @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                            @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="is_auth('feedback.feedback.getfeedbacklist')" icon="el-icon-download"
                            size="medium" type="primary" @click="exportInfo">导出
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table v-loading="loading" :data="tableData" border size="medium">
                <!-- <el-table-column prop="job_number" label="工号" width="150" /> -->
                <el-table-column prop="avatar_url" label="头像" width="80">
                    <template slot-scope="scope">
                        <el-avatar shape="square" :size="22" :src="scope.row.avatar_url"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="user_nick" label="昵称" />
                <el-table-column prop="mobile" label="手机号">
                    <template slot-scope="scope">
                        <span v-if="scope.row.mobile">{{ scope.row.mobile }}</span>
                        <p v-else style="text-align: center;">/</p>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="问题类型" />
                <el-table-column prop="name" label="问题内容">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="seeContent(scope.row.content)">
                            查看内容
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="反馈时间" />
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('feedback.feedback.getfeedbackinfo')" @click="goDetail(scope.row)"
                            type="primary" size="mini">查看
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
                layout="prev, pager, next, total" :current-page.sync="page" :total="count">
            </el-pagination>
        </div>
        <el-dialog title="问题描述" :visible.sync="isContentShow" width="600px" @close="clearState">
            <p>{{ content }}</p>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            page_name: '问题反馈',
            issearch: false,
            searchtime: null,
            loading: true,
            search: {},         // 搜索条件
            tableData: [],      // 列表内容
            categoryList: [], // 意见反馈类别列表
            count: 0,           // 数据总条数
            page: 1,            // 当前页数

            isContentShow: false, // 问题描述弹窗是否显示

            content: "", // 问题描述内容
        }
    },
    created() {
        this.init()
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth);
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth);
        },
        // 初始化
        init() {
            this.search = {
                category_id: 0,
            }
            this.searchtime = null;
            this.is_search();
            this.getcategorylist();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "feedback.feedback.getfeedbacklist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            // 判断时间条件
            if (this.searchtime !== null) {
                postdata.start_time = this.searchtime[0]
                postdata.end_time = this.searchtime[1]
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.total
                } else {
                    this.tableData = []
                    this.count = 0
                    this.$message.error(json.message);
                }
            })
        },
        // 获取意见反馈内容
        getcategorylist() {
            let postdata = {
                api_name: "feedback.category.getcategorylist",
                token: this.Tool.get_l_cache('token'),
            };
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.categoryList = json.data.list
                }
            })
        },
        // 前往查看详情页
        goDetail(item) {
            this.$router.push({ path: "/systemset/feedback/detail", query: { id: item.id } });
        },
        seeContent(content) {
            this.isContentShow = true;
            this.content = content;
        },
        // 关闭问题描述弹窗，清除数据
        clearState() {
            this.isContentShow = false;
            this.content = "";
        },
        // 导出
        exportInfo() {
            let postdata = {
                api_name: "feedback.feedback.getfeedbacklist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
                export: "yes",
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            // 判断时间条件
            if (this.searchtime !== null) {
                postdata.start_time = this.searchtime[0]
                postdata.end_time = this.searchtime[1]
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                if (json.code === 10005) {
                    this.Tool.errormes(json);
                } else {
                    location.href = json;
                }
                this.loading = false;
            })
        }
    },
}
</script>